<template>
  <div>
  <v-dialog
      v-model="alert.show"
      max-width="480"
  >
    <v-card>
      <v-card-title class="text-h5">
        <span class="alert-title">{{ alert.title }}</span>
      </v-card-title>

      <v-card-text>
        {{ alert.text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>


        <v-btn
            color="primary"
            text
            @click="alert.show = false"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "AlertComponent",
  computed: {
    ...mapState({
      alert: "alert"
    })
  }
}
</script>

<style scoped>
  .alert-title{
    text-align: center;
    width: 100%;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
</style>