import Vue from 'vue'
import Vuex from 'vuex'
import {api} from "@/api/api";
import auth from './auth'
import events from './events'
import banner from './banner'
import moment from "moment";

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
     alert: {
       show: false,
       title: '',
       text: ''
     },
     serverTime: null,
  },
  mutations: {
    ['SET_ALERT']: (state, payload) => {
      state.alert = payload
    },
    ['SET_SERVER_TIME']: (state, payload) => {
      state.serverTime = payload
    },
  },
  actions: {
    async init_app() {
      console.log(window.localStorage.getItem('api_token'), window.localStorage.getItem('user'))
      if (window.localStorage.getItem('api_token') && window.localStorage.getItem('user')){
        if (window.localStorage.getItem('api_token') !== 'null'){
          await this.dispatch('auth/set_api_token', window.localStorage.getItem('api_token'), { root: true});
          await this.dispatch('auth/set_data_user', JSON.parse(window.localStorage.getItem('user')), { root: true});
        }
      }

      await this.dispatch('get_server_time');
      await this.dispatch('events/load_events', {from_date: moment().format('YYYY-MM-DD')})
      await this.dispatch('events/load_types', {from_date: moment().format('YYYY-MM-DD')})
      await this.dispatch('banner/get_banner')
    },
    showAlert({commit}, payload){
      commit('SET_ALERT', { ...payload, show: true})
    },
    async get_server_time({ commit }) {
      try {
        const res = await api.server_time.get();
  
        if (res.data) {
          commit('SET_SERVER_TIME', res.data);
        }
      } catch (e) {
        console.error('Unable to load server time', e)
      }
    },
  },
  getters: {
    getWeekDates: (state) => {
      const currentTime = state.serverTime || Date.now();
      const today = moment.parseZone(currentTime);
      let days = [];

      days.push(today.format('YYYY-MM-DD'))
      for (let i = 1; i < 7; i++) {
        days.push(today.add(1, 'day').format('YYYY-MM-DD'))
      }
      return days;
    }
  },
  modules: {
    auth,
    events,
    banner
  },
})
