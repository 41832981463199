<template>
  <v-app>
    <Header/>
    <router-view>
    </router-view>
    <Footer/>
    <AlertComponent></AlertComponent>
  </v-app>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import AlertComponent from "./components/AlertComponent";
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    AlertComponent,
    Header, 
    Footer
  },
  async created() {
    await this.initApp();
  },
   methods: {
    ...mapActions({
        initApp: 'init_app',
        showAlert: 'showAlert'
      }),
   }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  position: relative;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-select {
  width: 100%;
}

.v-select__selections {
  height: 100% !important;
}

.v-select__selection {
  max-width: calc(100% - 50px) !important;
  width: 100% !important;



  &.v-select__selection--comma {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    margin: 0 !important;
  }
}

.v-list-item__title {
  width: 100%;
  max-width: 600px;
  text-overflow: unset !important;
  white-space: unset !important;
  text-align: left !important;
  line-height: 24px !important;
}

.v-input__append-inner {
  height: 100%;
  display: flex;
  align-items: center;
}


</style>
