<template>
  <div class='header'>
    <div class="header__city">
        <router-link :to="{name: 'main'}">
          Казань
        </router-link>
    </div>
    <div class="header__action" v-if="!getIsAuthState">
      <v-btn elevation="0" color="#FFFFFF" class="header__action--btn" @click="toAuth">
        Войти
      </v-btn>
    </div>
    <div class="header__hello-text" v-else>
      <v-tooltip bottom v-model="show" >
        <template  v-slot:activator="{ on }">
            <v-btn
              class="hello-button"
              @click="show = !show"
              v-on="!on"
            >
              Привет, {{ user.name }}
            </v-btn>
          </template>
          <div
            class="header__tooltip"
          >
             <v-btn
              class="header__personal-area-button"
              @click="toPersonalArea"
            >
              личный кабинет
            </v-btn>
             <v-btn
              class="header__personal-area-button"
              @click="logOut"
            >
              выход
            </v-btn>
          </div>
      </v-tooltip>
        
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {api} from "@/api/api";
export default {
  data: function() {
    return {
      show: false,
    }
  },
  computed: {
    routerName() {
      return this.$router.history.current.name &&  ['auth', 'registration'].includes(this.$router.history.current.name)
    },
    ...mapGetters({
        getIsAuthState :'auth/getIsAuthState',
        user: 'auth/getDataUser'
    }),
  },
  methods: { 
    ...mapActions({
      set_is_auth: 'auth/set_is_auth'
    }),
    toAuth () {
      this.$router.push('/auth');
    },
    toPersonalArea() {
      this.$router.push('/personal');
      this.show = false;
    },
    async logOut(){

      this.set_is_auth(false);
      this.show = false;
      this.$router.push({name: 'main'});
      window.localStorage.clear();

      try {
        await api.logout.post();
      } catch (e) {
        console.error(e);
      }


    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
      height: 56px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 80px;
      justify-content: space-between;

      background: #000000;
      position: relative;
      z-index: 2;

      

      &__action {

        color: #FFFFFF;
        

        &--icon {
          width: 16px;
          height: 16px;

          background: url('../assets/svg/enter-icon.svg') no-repeat;
          background-size: contain;

          margin-left: 8px;
        }

        button { 
            color: #0D4CD3;
          }

        &--btn {
          font-size: 20px;
          font-weight: 900;
          border-radius: 40px;
          color: #0D4CD3;

          
          
          @media screen and (max-width: 1439px) {
            font-size: 14px !important;
          }

          @media screen and (max-width: 768px) {
            font-size: 12px !important;
            padding: 4px 16px !important;
          }
        }
      }

      @media screen and (max-width: 1439px) {
        padding: 0px 40px;
      }

      @media screen and (max-width: 768px) {
        padding: 0px 20px;
      }

      &__city {

        a {
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          color: #FFFFFF;
          text-decoration: none;

          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
          
      }

      .title {
        font-style: normal;
        font-weight: 500;
        color: #FFFFFF;
        text-transform: none;
        font-size: 20px;
        text-align: start;

          @media screen and (max-width: 1439px) {
            font-size: 16px !important;
          }

          @media screen and (max-width: 768px) {
            font-size: 14px !important;
          }
      }
      .icon {
        margin-left: 8px;
      }

      .btn-login {
        display: flex;
        align-items: center;
        border-radius: 40px;
        padding: 4px 16px;
      }

      &__hello-text {
          .hello-button {
            background: none !important;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;  
            color: #fff;

            @media screen and (max-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
      }

      &__personal-area-button {
        width: 100%;
        height: 100%;

        background: none !important;
        box-shadow: none !important;
        border: none !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;  
        color: #fff !important;

        @media screen and (max-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }

       &__tooltip{
        display: grid;
        grid-auto-flow: row;
        gap: 15px;

        button {
          &:hover {
            background: none !important;
            box-shadow: none !important;
          }

          &::before {
            content: none !important;
          }

          &:active {
            background: none !important;
            box-shadow: none !important;
          }
        }
      }
  }

  .v-tooltip__content {
    pointer-events: all !important;
  }


 
</style>


   

