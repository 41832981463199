var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"registration"},[_c('h1',{staticClass:"registration__title"},[_vm._v(" Зарегистрироваться на сайте Уличные Артисты Казани ")]),_c('div',{staticClass:"registration__inputs"},[_c('div',{staticClass:"registration__inputs--login"},[_c('ValidationProvider',{attrs:{"name":"Имя","rules":"required|alpha_spaces","imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Введите имя","outlined":"","dense":"","hide-details":"auto","error-messages":errors,"height":"56px","type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"registration__inputs--password"},[_c('ValidationProvider',{attrs:{"name":"Пароль","rules":"required","imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Введите пароль","outlined":"","dense":"","height":"56px","hide-details":"auto","error-messages":errors,"type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('div',{staticClass:"registration__inputs--password"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{required: true, email: true, regex:/^([^а-яА-Я])*$/},"imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Введите email","outlined":"","dense":"","height":"56px","type":"email","hide-details":"auto","error-messages":errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"registration__inputs--subscribe"},[_c('ValidationProvider',{attrs:{"name":"Subscribe","rules":{ required: { allowFalse: false } },"imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Согласие на участие и обработку данных","error-messages":errors},model:{value:(_vm.form.subscribe),callback:function ($$v) {_vm.$set(_vm.form, "subscribe", $$v)},expression:"form.subscribe"}})]}}],null,true)})],1)]),_c('div',{staticClass:"registration__enter"},[_c('v-btn',{staticClass:"btn-enter",staticStyle:{"color":"#fff"},attrs:{"width":"100%","color":"#1D1D1D","elevation":"0","height":"56px","disabled":invalid},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v(" Зарегистрироваться ")])],1),_c('div',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.error_text)+" ")]),_c('div',{staticClass:"registration__no-account"},[_vm._v(" Есть аккаунт? "),_c('router-link',{attrs:{"to":"/auth"}},[_vm._v(" Войти ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }