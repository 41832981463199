<template>
  <section>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <v-form class='registration'>
        <h1 class="registration__title">
          Зарегистрироваться на сайте Уличные Артисты Казани
        </h1>

        <div class="registration__inputs">
          <div class="registration__inputs--login">
            <ValidationProvider name="Имя" rules="required|alpha_spaces" imediate v-slot="{errors}">
            <v-text-field
                placeholder="Введите имя"
                outlined
                dense
                hide-details="auto"
                :error-messages="errors"
                height="56px"
                v-model="form.name"
                type="text"
            />
            </ValidationProvider>
          </div>
          <div class="registration__inputs--password">
            <ValidationProvider name="Пароль" rules="required" imediate v-slot="{errors}">
            <v-text-field
                placeholder="Введите пароль"
                outlined
                dense
                height="56px"
                hide-details="auto"
                :error-messages="errors"
                type="password"
                v-model="form.password"
            />
            </ValidationProvider>
          </div>
          <div class="registration__inputs--password">
            <ValidationProvider name="Email" :rules="{required: true, email: true, regex:/^([^а-яА-Я])*$/}" imediate v-slot="{errors}">
            <v-text-field
                placeholder="Введите email"
                outlined
                dense
                height="56px"
                type="email"
                hide-details="auto"
                :error-messages="errors"
                v-model="form.email"
            />
            </ValidationProvider>
          </div>
          <div class="registration__inputs--subscribe">
            <ValidationProvider name="Subscribe" :rules="{ required: { allowFalse: false } }" imediate v-slot="{errors}">
            <v-checkbox
                v-model="form.subscribe"
                label="Согласие на участие и обработку данных"
                :error-messages="errors"
            ></v-checkbox>
            </ValidationProvider>
          </div>
        </div>

        <div class="registration__enter">
          <v-btn
              class="btn-enter"
              style="color: #fff;"
              width="100%"
              color="#1D1D1D"
              elevation="0"
              height="56px"
              :disabled="invalid"
              @click.prevent="handleSubmit(submit)"
          >
            Зарегистрироваться
          </v-btn>
        </div>
        <div class="error-text">
          {{ error_text }}
        </div>

        <div class="registration__no-account">
          Есть аккаунт?
          <router-link to="/auth">
            Войти
          </router-link>
        </div>
      </v-form>
    </ValidationObserver>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {api} from "@/api/api";
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import { extend } from "vee-validate";
import {
    regex,
    required, 
    email,
    alpha_spaces
} from 'vee-validate/dist/rules';

extend('regex', {
    ...regex,
    message: 'Введите корректное значение'
});

extend('alpha_spaces', {
    ...alpha_spaces,
    message: 'Только буквы'
});

extend('required', {
    ...required,
});

extend('email', {
    ...email,
});
export default {
  name: 'registration',
  components: {
    ValidationProvider, ValidationObserver
  },
  data: () => ({
    form: {
      name: null,
      password: null,
      email: null,
      subscribe: false,
    },
    isError: false,
    error_text: '',
  }),
  computed: {
    ...mapGetters({
      getIsAuthState: 'auth/getIsAuthState',
    }),
  },
  methods: {
    ...mapActions({
      set_api_token: 'auth/set_api_token',
      set_data_name: 'auth/set_data_name',
      set_data_phone: 'auth/set_data_phone',
      set_data_email: 'auth/set_data_email',
      initApp: 'init_app'
    }),
    async submit() {
      try {
        const res = await api.registration.post(this.form.name, this.form.email, this.form.password)

        if (!res.data.success) {
          this.error_text = res.data.data.email[0];
          return;
        }

        await this.set_data_name(res.data.data.user.name);
        await this.set_data_phone(res.data.data.user.phone);
        await this.set_data_email(res.data.data.user.email);
        await this.set_api_token(res.data.data.api_token);
        await this.initApp();
        await this.$router.push({name: 'main'});
        this.isError = false;
      } catch (e) {

        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.registration {
  padding: 32px;
  background: #ffff;

  height: min-content;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  width: 544px;
  border-radius: 4px;

  @media screen and (max-width: 1439px) {
    padding: 26px;

  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }


  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    max-width: 277px;
    display: flex;
    align-items: flex-start;
    text-align: start;
    @media screen and (max-width: 1439px) {
      font-size: 16px;

    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      max-width: 100%;
    }
  }

  &__inputs {
    margin-top: 40px;

    @media screen and (max-width: 1439px) {
      margin-top: 20px;

    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }

    &--password {
      margin-top: 16px;

      @media screen and (max-width: 1439px) {
        margin-top: 14px;
      }

      @media screen and (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }

  &__enter {
    margin-top: 16px;
    @media screen and (max-width: 1439px) {
      margin-top: 14px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 12px;
    }
  }

  &__public-services {
    margin-top: 16px;
    margin-bottom: 112px;

    @media screen and (max-width: 1439px) {
      margin-top: 14px;
      margin-bottom: 80px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }

  .error-text {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    color: red;
    font-size: 14px;

    @media screen and (max-width: 1439px) {
      height: 30px;
      font-style: 12px;
    }

    @media screen and (max-width: 768px) {
      height: 20px;
      font-style: 1px;
    }
  }
}


.btn-public-service {
  text-transform: none;
}

.btn-enter {
  text-transform: none;
}
</style>



   

