import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import RegistrationPage from '../views/RegistrationPage.vue'
import PersonalAreaPage from '../views/PersonalAreaPage.vue'
import Main from '../views/Main.vue'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/auth',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/auth/login/esia',
    name: 'auth.login.esia',
    component: LoginPage
  },
  {
    path: '/auth/login/esia/state',
    name: 'auth.login.esia.state',
    component: LoginPage
  },
  {
    path:'/auth/registration',
    name: 'registration',
    component: RegistrationPage
  },
  {
    path:'/personal',
    name: 'personal_area',
    component: PersonalAreaPage,
    meta: { requiresLogin: true }
  }
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && !store.state.auth.api_token) {
    next("/auth")
  } else {
    next()
  }
})
export default router
