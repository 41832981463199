import {api} from "@/api/api";

export default {
  namespaced: true,
  state: {
    banner: null,
  },
  mutations: {
    ['SET_BANNER']: (state, payload) => state.banner = payload,
  },
  actions: {
    get_banner: async ({commit}) => {
      try {
        const res = await api.banner.get();
        commit('SET_BANNER', res.data)
      } catch (e) {
        console.error('Unable to load banner', e)
      }
    }
  },
  getters: {
    getBanner: state => state.banner
  }
}
