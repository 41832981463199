<template>
  <section class="auth__form">
    <ValidationObserver
      v-if="APP_ENV !== 'production'"
      tag="div"
      v-slot="{ handleSubmit, invalid }"
    >
      <v-form class="auth">

        <h1 class="auth__title">
          Вход на сайт Уличные Артисты Казани
        </h1>

        <div class="auth__inputs">
          <div class="auth__inputs--login">
            <ValidationProvider name="E-mail" rules="required|email" imediate v-slot="{errors}">
              <v-text-field
                  placeholder="Введите email"
                  outlined
                  height="56px"
                  v-model="form.email"
                  type="mail"
                  hide-details="auto"
                  :error-messages="errors"
                  :v-model="form.email"
              />
            </ValidationProvider>
          </div>
          <div class="auth__inputs--password">
            <ValidationProvider name="Password" rules="required" imediate v-slot="{errors}">
              <v-text-field
                  placeholder="Введите пароль"
                  outlined
                  height="56px"
                  v-model="form.password"
                  type="password"
                  hide-details="auto"
                  :error-messages="errors"
              />
            </ValidationProvider>
          </div>
        </div>

        <div class="auth__enter">
          <v-btn
              class="btn-enter"
              style="color: #fff;"
              width="100%"
              color="#1D1D1D"
              height="56px"
              elevation="0"
              :disabled="invalid"
              @click.prevent="handleSubmit(submit)"
          >
            Войти
          </v-btn>

        </div>
      </v-form>
    </ValidationObserver>

    <div v-else>
      <v-card
          class="mx-auto"
          max-width="480"
      >
        <v-card-title>
          Вход на сайт Уличные Артисты Казани
        </v-card-title>

        <v-card-actions class="justify-sm-center">
          <v-btn
              class="auth__esia-btn"
              @click="esiaLogin()"
          >
            <div class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.7989 7.96116C22.7045 6.72434 21.7604 5.13867 20.8164 4.37755C19.7548 3.59863 18.6517 2.87872 17.5122 2.22105C16.3813 1.54551 15.2153 0.93151 14.0192 0.381672C13.3803 0.11901 12.6952 -0.010448 12.0052 0.00111198C11.315 -0.0135022 10.6293 0.116071 9.99126 0.381672C8.79537 0.931901 7.62939 1.54589 6.49827 2.22105C4.67311 3.33101 3.19409 4.37755 3.19409 4.37755C2.48642 4.97442 1.93514 5.72744 1.57757 6.57017C1.44705 6.87778 1.68687 7.19992 2.02102 7.19992H2.95088C3.32242 7.19992 3.6563 6.98872 3.86893 6.68404C4.10994 6.33869 4.39697 6.024 4.72461 5.74957C4.72461 5.74957 5.9458 4.89141 7.4528 3.98123C8.38674 3.42761 9.34947 2.92413 10.3369 2.47295C10.8637 2.25515 11.4299 2.1489 11.9998 2.16089C12.5695 2.15141 13.1351 2.25756 13.6627 2.47295C14.6503 2.92381 15.613 3.4273 16.5468 3.98123C17.4877 4.52053 18.3984 5.11085 19.275 5.74957C20.0545 6.37369 20.8339 7.67394 20.9119 8.68813C20.9119 8.68813 21.0938 10.1964 21.1197 11.983C21.1182 13.0863 21.0574 14.1888 20.9379 15.2856C20.7566 16.434 20.1658 17.4779 19.275 18.2241C19.275 18.2241 18.0538 19.1083 16.5468 20.0185C15.6128 20.5721 14.6501 21.0756 13.6627 21.5268C13.1359 21.7446 12.5697 21.8508 11.9998 21.8388C11.4307 21.8508 10.865 21.7473 10.3369 21.5346C9.34931 21.0837 8.38657 20.5802 7.4528 20.0263C5.9458 19.1421 4.72461 18.2319 4.72461 18.2319C4.43459 17.9997 4.14458 17.6739 3.88936 17.3041C3.68182 17.0033 3.35131 16.7998 2.9859 16.7998H2.03397C1.69555 16.7998 1.45715 17.1289 1.59772 17.4368C1.98749 18.2904 2.59079 19.1136 3.19409 19.6C3.19409 19.6 4.67311 20.7099 6.49827 21.7882C7.62918 22.4637 8.79518 23.0777 9.99126 23.6276C10.6309 23.887 11.3159 24.0132 12.0052 23.9986C12.6954 24.0132 13.3812 23.8836 14.0192 23.618C15.2151 23.0678 16.3811 22.4538 17.5122 21.7787C19.3374 20.6687 20.8164 19.5904 20.8164 19.5904C21.8954 18.6804 22.6108 17.4074 22.8304 16.0068C22.9752 14.6692 23.0487 13.3248 23.0506 11.9792C23.0192 9.80054 22.7989 7.96116 22.7989 7.96116Z"
                    fill="url(#paint0_linear_11_2162)"/>
                <path
                    d="M0.959961 9.36146C0.959961 9.09637 1.17486 8.88147 1.43996 8.88147H8.87993C9.14502 8.88147 9.35993 9.09637 9.35993 9.36146V10.8014C9.35993 11.0665 9.14502 11.2814 8.87993 11.2814H0.959961V9.36146Z"
                    fill="#0066B3"/>
                <path
                    d="M0.959961 12.7213H16.0799C16.345 12.7213 16.5599 12.9362 16.5599 13.2013V14.6413C16.5599 14.9064 16.345 15.1213 16.0799 15.1213H1.43996C1.17486 15.1213 0.959961 14.9064 0.959961 14.6413V12.7213Z"
                    fill="#EE2F53"/>
                <defs>
                  <linearGradient id="paint0_linear_11_2162" x1="12.2004" y1="0" x2="12.2004" y2="23.9997"
                                  gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0066B3"/>
                    <stop offset="0.354167" stop-color="#0066B3"/>
                    <stop offset="0.6875" stop-color="#EE2F53"/>
                    <stop offset="1" stop-color="#EE2F53"/>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            Войти через Госуслуги
          </v-btn>
        </v-card-actions>
      </v-card>


    </div>
  </section>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {api} from "@/api/api";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  inject: ["APP_ENV"],
  data: () => ({
    isError: false,
    loginFromEsia: false,
    form: {
      email: null,
      password: null
    }
  }),
  components: {
    ValidationProvider, ValidationObserver
  },
  computed: {
    ...mapGetters({
      getIsAuthState: 'auth/getIsAuthState',
    }),
    env() {
      return process.env.NODE_ENV || 'production'
    }
  },
  async mounted() {
    if (this.$route.name === 'auth.login.esia') {
      this.loginFromEsia = true;
      const urlParams = new URLSearchParams(window.location.search);
      this.set_api_token(urlParams.get('token'));

      try {
        let response = await api.profile.get();
        this.set_data_user(response.data.data.user);
        await this.initApp();
        await this.$router.push({name: 'main'});
      } catch (e) {
        //Вывод об шоибке
        console.error(e);
      }
    }

    if (this.$route.name === 'auth.login.esia.state') {
      this.loginFromEsia = true;
      const urlParams = new URLSearchParams(window.location.search);
      window.location.href = api.domain + '/esia/checkUser?state=' + urlParams.get('state') + '&code=' + urlParams.get('code')
    }
  },
  methods: {
    ...mapActions({
      set_api_token: 'auth/set_api_token',
      initApp: 'init_app',
      set_data_user: 'auth/set_data_user',
      set_user_events: 'events/set_user_events'
    }),
    async submit() {
      try {
        const res = await api.login.post(this.form.email, this.form.password)

        this.set_api_token(res.data.data.api_token);
        this.set_data_user(res.data.data.user);


        await this.initApp();
        await this.$router.push({name: 'main'});

        this.isError = false;
        this.error_text = '';
      } catch (e) {
        this.isError = true;
        this.error_text = 'Не верный логин или пароль'

      }
    },
    async esiaLogin() {
      try {
        const response = await api.login.getAuthLink();
        this.loginFromEsia = true;
        window.location.href = response.data.link;
      } catch (e) {
        this.isError = true;
        this.error_text = 'Не уддалось войти через сервис Госуслуги'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  padding: 32px;
  background: #ffff;

  height: min-content;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  width: 544px;
  border-radius: 4px;

  &.auth_hidden{
    display: none;
  } 

  @media screen and (max-width: 1439px) {
    padding: 26px;

  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }

  &__form {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  &__esia-btn {
    color: #0D4CD3;

    .icon {
      margin-right: 5px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    display: flex;
    align-items: flex-start;
    text-align: start;
    flex: 1;

    @media screen and (max-width: 1439px) {
      font-size: 16px;

    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      max-width: 100%;
    }

  }

  &__inputs {
    margin-top: 40px;

    @media screen and (max-width: 1439px) {
      margin-top: 20px;

    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }

    &--password {
      margin-top: 16px;

      @media screen and (max-width: 1439px) {
        margin-top: 14px;
      }

      @media screen and (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }

  &__enter {
    margin-top: 16px;

    @media screen and (max-width: 1439px) {
      margin-top: 14px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 12px;
    }
  }

  &__public-services {
    margin-top: 16px;
    margin-bottom: 112px;

    @media screen and (max-width: 1439px) {
      margin-top: 14px;
      margin-bottom: 80px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
}

.error-text {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  color: red;
  font-size: 14px;

  @media screen and (max-width: 1439px) {
    height: 30px;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    height: 20px;
    font-size: 0px;
  }
}


.btn-public-service {
  text-transform: none;
}

.btn-enter {
  text-transform: none;
}
</style>



   

