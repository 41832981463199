import {api} from "@/api/api";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        events: [],
        selected_event: null,
        types_events: null,
        user_events: null
    },
    mutations: {
        ['EVENTS']: (state, payload) => state.events = payload,
        ['SET_SELECTED_EVENT']: (state, payload) => state.selected_event = payload,
        ['SET_USER_EVENTS']: (state, payload) => state.user_events = payload,
        ['TYPES_EVENTS']: (state, payload) => state.types_events = payload,
    },
    actions: {
        set_events: ({commit}, payload) => {
            commit('EVENTS', payload)
        },
        set_user_events: ({commit}, payload) => {
            commit('SET_USER_EVENTS', payload);
            window.localStorage.setItem("user_events", JSON.stringify(payload));
        },
        set_selected_event: async ({commit}, payload) => {

            if (payload === null || payload === undefined){
                commit('SET_SELECTED_EVENT', null)
                return;
            }

            try {
                const response = await  api.events.get_event(payload.id, payload.date);
                commit('SET_SELECTED_EVENT', response.data)
            } catch (e) {
                console.error('Unable to load event', e)
            }
        },
        set_types_events: ({commit}, payload) => {
            commit('TYPES_EVENTS', payload)
        },
        load_events: async ({dispatch, commit}, payload) => {
            try {
                const res = await api.events.get_events(payload.from_date, payload.type_id || null);
                if (res.data) {
                    dispatch('set_events', res.data)
                    dispatch('set_selected_event',  {
                        id: res.data[Math.floor(Math.random()*res.data.length)].id,
                        date: moment().format('YYYY-MM-DD')
                    });
                }
            } catch (e) {
                console.error('Unable to load events', e)
            }
        },
        load_types: async ({dispatch, commit}) => {
            try {
                const res = await api.events.get_type_events();

                if (res.data) {
                    dispatch('set_types_events', res.data)
                }

            } catch (e) {
                console.error('Unable to load event types', e)
            }
        }
    },
    getters: {
        getEvents: (state) => state.events,
        getSelectedEvent: (state) => state.selected_event,
        getTypesEvents: (state) => state.types_events,
        getUserEvents: (state) => state.user_events
    }
}
