var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"place-form",class:{ 'place-form__mobile': _vm.isMobile, 'is-finish': !_vm.isNotFinish}},[(!_vm.isMobile)?_c('div',{staticClass:"btn-close",on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1):_vm._e(),(_vm.isNotFinish)?[((!_vm.isMobile && _vm.isFirstStep && _vm.selectedEvent) || (_vm.isMobile &&  _vm.selectedEvent))?[_c('div',{staticClass:"place-form__head"},[_c('div',{staticClass:"circle"},[_c('img',{attrs:{"src":_vm.getIconUrl(_vm.selectedEvent.type.type_id),"alt":""}})]),_c('div',{staticClass:"place-form__head--title"},[_c('span',[_vm._v(_vm._s(_vm.selectedEvent.title))])])]),(_vm.userIsBlocked)?_c('div',{staticClass:"error-blocked"},[_vm._v(" Вы заблокированы до "+_vm._s(_vm.getDateUserBlocked)+" включительно ")]):_vm._e(),_c('div',{staticClass:"place-form__description"},[_vm._v(" "+_vm._s(_vm.selectedEvent.description)+" ")]),_c('div',{staticClass:"place-form__bread-crumbs"},[_c('div',{staticClass:"place-form__bread-crumb"},[_vm._v(" "+_vm._s(_vm.selectedEvent.site_area)+" м2 ")]),_c('div',{staticClass:"place-form__bread-crumb"},[_vm._v(" до "+_vm._s(_vm.selectedEvent.number_of_people)+" человек ")])]),(_vm.selectedEvent && _vm.selectedEvent.attachment.length !== 0)?_c('div',{staticClass:"place-form__picture"},[_c('div',{staticClass:"image",style:({backgroundImage: ("url(" + (_vm.selectedEvent.attachment[0].url) + ")")})})]):_vm._e(),_c('div',{staticClass:"place-form__days"},[_c('div',{staticClass:"place-form__days--title"},[_vm._v(" Ближайшие даты ")]),_c('div',{staticClass:"place-form__days--blocks"},_vm._l((_vm.days),function(day){return _c('div',{key:("day_" + day),staticClass:"day-block",class:{
                        'active': day === _vm.formData.date
                      },on:{"click":function($event){return _vm.setDate(day)}}},[_c('div',{staticClass:"name "},[_vm._v(" "+_vm._s(_vm.$moment(day).format('dd'))+" ")]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.$moment(day).format('DD'))+" ")])])}),0)]),_c('div',{staticClass:"place-form__times"},[_c('div',{staticClass:"place-form__times--title"},[_vm._v(" Время ")]),_c('div',{staticClass:"place-form__times--blocks"},_vm._l((_vm.getDateTimes),function(item){return _c('div',{key:item.id,staticClass:"place-form__times--block",class:{
                        'active' : item.id === _vm.formData.time_id,
                        'disable' : _vm.checkStatus(item.id)},on:{"click":function($event){return _vm.setTime(item)}}},[_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(item.time_at.slice(0, 5))+" ")])])}),0)]),(!_vm.isMobile)?_c('div',{staticClass:"place-form__choose"},[_c('v-btn',{staticClass:"btn-choose",staticStyle:{"color":"#fff"},attrs:{"disabled":!_vm.formData.time_id,"color":"#3763FF","elevation":"0","x-large":""},on:{"click":_vm.nextStep}},[_vm._v(" Выбрать ")])],1):_vm._e()]:_vm._e(),((!_vm.isMobile && !_vm.isFirstStep) || _vm.isMobile)?[(!_vm.isMobile)?_c('div',{staticClass:"place-form__second"},[_c('v-btn',{staticClass:"btn-back",staticStyle:{"color":"blue"},attrs:{"x-small":"","elevation":"0","color":"#fff"},on:{"click":_vm.backToStepOne}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Изменить дату или место ")],1)],1):_vm._e(),_c('div',{staticClass:"place-form__inputs"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var invalid = ref.invalid;
return [_c('v-form',[_c('ValidationProvider',{attrs:{"name":"full_name","rules":"required|alpha_spaces","imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"outlined":"","dense":"","error-messages":errors,"placeholder":"ФИО"},model:{value:(_vm.formData.full_name),callback:function ($$v) {_vm.$set(_vm.formData, "full_name", $$v)},expression:"formData.full_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":{required: true, email: true, regex:/^([^а-яА-Я])*$/},"imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"error-messages":errors,"outlined":"","dense":"","placeholder":"Электронная почта","type":"email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phone","rules":{regex:/^\+7{1}\ \(\d{3}\)\ \d{3}-\d{2}-\d{2}$/, required: true},"imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ###-##-##'),expression:"'+7 (###) ###-##-##'"}],attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"outlined":"","type":"phone","dense":"","return-masked-value":"","error-messages":errors,"color":"deep-purple","placeholder":"+7"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"team_name","rules":"required|alpha_spaces","imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"outlined":"","dense":"","error-messages":errors,"placeholder":"Название коллектива или творческий псевдоним"},model:{value:(_vm.formData.team_name),callback:function ($$v) {_vm.$set(_vm.formData, "team_name", $$v)},expression:"formData.team_name"}})]}}],null,true)}),_c('vue-tags-input',{ref:"tags-input",staticClass:"tags-input",attrs:{"tags":_vm.formData.persons,"placeholder":"ФИО участников"},on:{"tags-changed":function (newTags) { return _vm.formData.persons = newTags; }},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}}),_c('ValidationProvider',{attrs:{"name":"category","rules":"required|alpha_spaces","imediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"outlined":"","dense":"","error-messages":errors,"placeholder":"Жанр выступления"},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})]}}],null,true)}),(false)?_c('v-file-input',{attrs:{"disabled":_vm.loading || _vm.userIsBlocked,"label":"Прикрепите фото","outlined":"","dense":"","id":"input-file","prepend-icon":""},on:{"change":_vm.setPhoto}}):_vm._e(),_c('div',{ref:"captcha-container",attrs:{"id":"captcha-container"}}),_c('div',{staticClass:"place-form__choose"},[_c('v-btn',{staticClass:"btn-choose",staticStyle:{"color":"#fff"},attrs:{"loading":_vm.loading,"disabled":_vm.loading || invalid || _vm.formData.persons.length === 0 || !_vm.formData.time_id || !_vm.formData.smart_token,"color":"#3763FF","elevation":"0","x-large":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Забронировать ")])],1)],1)]}}],null,false,961425976)})],1)]:_vm._e()]:_vm._e(),(!_vm.isNotFinish)?[_c('div',{staticClass:"place-form__finish-title"},[_vm._v(" Готово! ")]),_c('div',{staticClass:"place-form__finish-description"},[_vm._v(" Ваша заявка принята. ")]),_c('div',{staticClass:"place-form__choose"},[_c('v-btn',{staticClass:"btn-choose",staticStyle:{"color":"#fff"},attrs:{"color":"#3763FF","elevation":"0","x-large":""},on:{"click":function($event){return _vm.close(false)}}},[_vm._v(" Хорошо ")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }