<template>
  <div class="calendar__event" v-if="event">
      <div class="calendar__event-time">{{ event.time_at_request.slice(0, 5) }}</div>
      <div class="calendar__event-team">{{ event.team_name }}</div>
  </div>
</template>

<script>
export default {
name: "CalendarEventComponent",
  props: {
     event: {
       type: Object,
       default: () => {
         return {}
       }
     }
  }
}
</script>

<style scoped lang="scss">
.calendar {
  &__event {
    display: flex;
    flex-direction: column;
    background: #5C59F5;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    flex: 1;
    height: 100%;
    text-align: start;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16px;
    }

    &-time {
      display: none;
      width: 100px;
      margin-bottom: 8px;
      text-align: start;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}
</style>
