<template>
  <div
    v-if="banner && banner.is_active === 1"
    class="app-banner"
  >
    <div class="app-banner__content">
      <h3 v-html="banner.title" />
      <p v-html="banner.content" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Banner',
  computed: {
    ...mapGetters({
      banner: 'banner/getBanner',
    })
  },
}
</script>

<style lang="scss">
.app-banner {
  position: relative;
  z-index: 2;
  margin: 32px 0 40px;
  padding: 0 80px;

  @media (max-width: 1440px) {
    padding: 0 36px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFFFFF;
    padding: 32px;
    border-radius: 4px;

    p {
      white-space: pre-wrap;
    }

    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 24px;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3A3A3A;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
