<template>
  <div class="personal-area">
    <modal 
      name="modal-cancel-event"
      :adaptive="true"
      :scrollable="true"
      :reset="true"
      height="auto"
    >
      <div class="cancel-event" >
        <div class="cancel-event__header">
          <div class="cancel-event__title">
            Отмена выступления
          </div>
          <div class="cancel-event__cancel-icon" @click="$modal.hide('modal-cancel-event');">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
        <div class="cancel-event__data">
          {{getAddress}}
        </div>
        <div class="cancel-event__event-date">
          <div class="cancel-event__user-info">
            <div class="cancel-event__user-info__name">
              {{formData.name}}
            </div>
            <div class="cancel-event__user-info__email">
              {{ formData.email}}
            </div>
            <div class="cancel-event__user-info__phone">
              {{ formData.phone}}
            </div>
          </div>

           <div class="cancel-event__team-info">
            <div class="cancel-event__user-info__name">
              {{ event_for_cancel && event_for_cancel.team_name}}
            </div>
            <div class="cancel-event__user-info__email">
              {{ event_for_cancel && event_for_cancel.fio}}
            </div>
            <div class="cancel-event__user-info__email">
              {{ event_for_cancel && event_for_cancel.description}}
            </div>
          </div>
        </div>
        <div class="cancel-event__actions">
          <v-btn
            outlined
            @click="$modal.hide('modal-cancel-event');"
            x-large
            color="#3763FF"
            >Нет, закрыть
          </v-btn>
          <v-btn
            @click="deleteEvent"
            color="error"
            x-large
            >Да, отменить
          </v-btn>
        </div>
      </div>
    </modal>
    <div class="personal-area__data">
      <div class="personal-area__title">
        Привет, {{getUserName}}
      </div>
      <div class="personal-area__description">
        Здесь вы можете управлять своими выступлениями и личными данными.
      </div>

      <div class="personal-area__person-data">
        <div class="personal-area__person-data-title">
          Мои данные
        </div>
        <div v-if="userIsBlocked" class="error-blocked">
          Вы заблокированы до {{getDateUserBlocked}} включительно
        </div>
        <div class="personal-area__person-data-inputs">
          <ValidationObserver>
            <form class="personal-area__person-data-inputs__wrapper">
              <div class="personal-area__person-data-input">
                <ValidationProvider name="full_name" rules="required|alpha_spaces" imediate v-slot="{errors}">
                  <v-text-field
                  class="input"
                  label="ФИО"
                  outlined
                  :disabled="!isEditUserDate"
                  :error-messages="errors"
                  hide-details="auto"
                  v-model="formData.name"
                  />
                </ValidationProvider>
              </div>
              <div class="personal-area__person-data-input">
                <ValidationProvider name="email"  :rules="{required: true, email: true, regex:/^([^а-яА-Я])*$/}" imediate v-slot="{errors}">
                  <v-text-field
                  class="input"
                  label="Email"
                  :disabled="!isEditUserDate"
                  outlined
                  hide-details="auto"
                  :error-messages="errors"
                  v-model="formData.email"
                  />
                </ValidationProvider>
              </div>
              <div class="personal-area__person-data-input">
                <ValidationProvider name="phone" :rules="{regex:/^\+7{1}\ \(\d{3}\)\ \d{3}-\d{2}-\d{2}$/}"  imediate v-slot="{errors}">
                  <v-text-field
                  class="input"
                  label="Номер телефона"
                  :disabled="!isEditUserDate"
                  outlined
                  type="phone"
                  return-masked-value
                  v-mask="'+7 (###) ###-##-##'"
                  hide-details="auto"
                   :error-messages="errors"
                  v-model="formData.phone"
                  />
                </ValidationProvider>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>

    </div>
    <div class="personal-area__timeTable">
      <div class="personal-area__timeTable-title">
        Мои выступления
      </div>
      <div class="personal-area__timeTable-description">
        Вы можете посмотреть информацию о забронированных выступлениях и отменить их, для этого нажми на слот.
      </div>
      <div class="personal-area__timeTable-wrapper">
        <TimeTableCalendar :requests="getUserEvents" @setForCancel="setEventForCancel" type="click"/>
      </div>
    </div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import TimeTableCalendar from '@/components/TimeTableCalendar.vue';
import {api} from "@/api/api";
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TimeTableCalendar,
    ValidationProvider,
    ValidationObserver
  },
  name: 'LoginPage',
  data: function () {
    return {
      isEditUserDate: false,
      formData: {
        name: '',
        email: '',
        phone: '',
        date_blocked_to: null
      },
      user_name: '',
      event_for_cancel: null,
    }
  },
  computed: {
    ...mapGetters({
      getDataUser: 'auth/getDataUser',
      getUserEvents: 'events/getUserEvents',
      getEvents: 'events/getEvents',
      getIsAuthState :'auth/getIsAuthState',
    }),
    getUserName() {
      return this.getDataUser.name;
    },
    getAddress() {
      if(this.event_for_cancel && this.event_for_cancel.address) {
        return this.event_for_cancel.address
      }

      return '';
    },
    userIsBlocked(){
      return this.getDataUser.is_blocked
    },
    getDateUserBlocked(){
      return new moment(this.getDataUser.date_blocked_to).format('DD.MM.YYYY')
    }
  },
  async mounted() {
    const {data} = await api.profile.get();
    this.set_data_user(data.data.user);
    const newArray =[...data.data.requests];
    this.set_user_events(newArray);
    this.cancelChangeData();
  },
  methods: {
    ...mapActions({
      set_data_user: 'auth/set_data_user',
      set_user_events: 'events/set_user_events',
    }),
    async deleteEvent() {
      try{
        await api.events.deleteEvent(this.event_for_cancel.id);

        const newArray = this.getUserEvents.filter(el => el.id !== this.event_for_cancel.id);
        this.set_user_events(newArray);
        this.$modal.hide('modal-cancel-event');
        
      }catch(e) {
        console.log(e)
      }
      
    },
    setEventForCancel(item) {
      const placeSelectedEvent = this.getEvents.find((el) => el.id === item.art_event_id);
      this.event_for_cancel = {
        ...item,
      };

      this.event_for_cancel.address = `
        ${placeSelectedEvent.address}. 
        ${this.$moment(this.event_for_cancel.date_request).format('dddd')}, 
        ${this.$moment(this.event_for_cancel.date_request).format('DD')} 
        ${this.$moment(this.event_for_cancel.date_request).format('MMMM')},
        ${this.event_for_cancel.time_at_request}
      ` ;

      this.$modal.show('modal-cancel-event');
    },
    cancelChangeData() {
      this.formData = {
        name: this.getDataUser.name,
        phone: this.getDataUser.phone ? this.getDataUser.phone : '',
        email: this.getDataUser.email ? this.getDataUser.email : '',
      };

      this.isEditUserDate = false
    },
  },
}
</script>

<style lang="scss" scoped>

.personal-area {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cancel-event {
    width: 100%;
    height: 100%;

    padding: 32px;

    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(4, max-content);

    gap: 24px;

    &__actions {
      display: grid;
      grid-auto-flow: column;
      gap: 16px;
      height: 52px;
    }

    &__user-info, &__team-info {
      display: grid;
      grid-auto-flow: row;
      gap: 16px;
      padding: 16px;

      border: 1px solid #B8B8B8;
      border-radius: 4px;

      &__name {
        text-align: start;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #000000;
      }

      &__email {
        text-align: start;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #000000;
      }

      &__phone {
        text-align: start;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #000000;
      }
    }

    &__event-date {
      display: grid;
      grid-auto-flow: row;
      gap: 15px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
    }

    &__cancel-icon {
      cursor: pointer;
    }

    &__data {
      text-align: start;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #3A3A3A;
    }
  }

  &__data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #151515;
    min-height: 476px;
    position: relative;
    padding: 60px 80px;

    @media screen and (max-width: 1439px) {
      padding: 36px 60px;
    }

    @media screen and (max-width: 768px) {
      padding: 16px 28px;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.6px;
    color: #FFFFFF;

    @media screen and (max-width: 1439px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 768px) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  &__description {
    margin-top: 24px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;

    @media screen and (max-width: 1439px) {
      font-size: 16px;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
      text-align: left;
    }
  }

  &__person-data {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;

    padding: 32px;
    background: #FFFFFF;
    border-radius: 4px;

    @media screen and (max-width: 1439px) {
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #000000;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    &-inputs {
      width: 100%;
      margin-top: 24px;

      &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 125px;

        @media screen and (max-width: 1439px) {
          gap: 16px;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }

        @media screen and (max-width: 768px) {
          gap: 20px;
          grid-template-columns: 1fr;
        }
      }

      .input {
        .v-text-field > .v-input__control > .v-input__slot:before {
          content: none !important;
          border: none !important;
        }

        .v-text-field > .v-input__control > .v-input__slot:after {
          content: none !important;
          border: none !important;
        }

        .v-text-field--outlined fieldset {
          border: none !important;
        }
      }

     
    }

    &-actions {
      margin-top: 24px;
      display: flex;
    }
  }
  
  &__change-date {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3763FF;

    cursor: pointer;
  }

  &__timeTable {
    display: flex;
    flex-direction: column;
    text-align: start;

    padding: 60px 80px;

    @media screen and (max-width: 1439px) {
      padding: 36px 60px;
    }

    @media screen and (max-width: 768px) {
      padding: 16px 28px;
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.4px;
      color: #000000;
    }

    &-description {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #3A3A3A;
      margin-top: 24px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }



  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    gap: 16px;
  }

  &__save-changes {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #3763FF;
  }

  &__cancel {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #E15B5B;
  }

  .error-blocked{
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background: #f5c2c7;
    color: #842029;
    border: 1px solid #842029;
    border-radius: 4px;
  }
}
</style>
