<template>
    <div class="app-container">
        <div class="app-container__content">
            <div class="app-container__photo"></div>
            <div class="app-container__title">
                Art-проспект
            </div>
            <div class="app-container__description">
                Art-проспект – открытая система online-бронирования, предоставляющая равные возможности выступать на открытых площадках Казани всем творческим жителям и гостям города.

                Участники проекта – представители всех творческих жанров (музыканты, чтецы, актеры, художники и другие).
            </div>
            <Categories/>
            <Banner/>
        </div>
        <Map/>
        <TimeTable></TimeTable>
    </div>
</template>

<script>
import Categories from '../components/Categories.vue'
import Banner from '../components/Banner.vue'
import Map from '../components/Map.vue'
import TimeTable from "@/components/TimeTable";

export default {
    name: 'Main',
    components: {TimeTable, Categories, Banner, Map},
    computed: { },
    
    methods: {
    },
}
</script>

<style lang="scss" >
    .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after{
        visibility: hidden !important;
    }

    .v-select__selection--comma {
        overflow: inherit !important;
    }
    
    .ymap-container {
        width: 100%;
        height: 100%;
    }
    .app-container {
      
      height: 100%;

      display: flex;
      flex-direction: column;

      
     
      background: #ffff;

        &__photo {
            width: 528px;
            height: 100%;
            position: absolute;

            top: 0;
            right: 0;
            background: url('../assets/main-bg-v2.jpeg');
            background-size: cover;
            background-position: center;

            @media screen and (max-width: 1439px) {
                width: 274px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
        }

        &__content  {
            position: relative;
            background: #151515;
            min-height: 652px;

            @media screen and (max-width: 1439px) {
                min-width: 0;
            }

            @media screen and (max-width: 768px) {
                min-width: 0;
                min-height: max-content;

            }
            
        }

        &__title {
           
            margin-left: 80px;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 56px;
            /* identical to box height, or 117% */

            letter-spacing: -0.6px;

            color: #FFFFFF;
            text-align: start;

            margin-top: 56px;

            @media screen and (max-width: 1439px) {
              margin-top: 30px;
              font-size: 38px;
              margin-left: 40px;
            }

            @media screen and (max-width: 768px) {
              margin-top: 20px;
              font-size: 28px;
              margin-left: 20px;
            }

        }

        &__description {
            margin-top: 24px;
            margin-left: 80px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            /* or 150% */


            color: #FFFFFF;
            max-width: 736px;
            display: flex;
            flex-direction: column;

            text-align: start;

            @media screen and (max-width: 1439px) {
              margin-top: 20px;
              font-size: 14px;
              margin-left: 40px;
              max-width: 422px;
            }

            @media screen and (max-width: 768px) {
              margin-top: 16px;
              font-size: 12px;
              margin-left: 20px;
              max-width: 100%;
            }

            span {
                margin-top: 20px;
                text-align: start;
            }

        }
    }
</style>
