<template>
  <section class="calendar" v-if="requests">
    <div class="calendar__container">
      <table class="calendar__table">
        <tr>
          <th class="calendar__cell calendar__cell--hide-on-mobile"></th>
          <th v-for="item in days" :key="`column_${item}`" class="calendar__cell">
            <div class="calendar__cell--date">{{ humanizeDate(item) }}</div>
          </th>
        </tr>
        <tr class="calendar__row" v-for="(time, rowIndex) in times" :key="`row_${rowIndex}`">
          <th class="calendar__cell calendar__cell--hide-on-mobile">
            <div class="calendar__cell--time">
              {{ time.from }}
              <span v-show="rowIndex === times.length - 1"> {{ time.to }}</span>
            </div>
          </th>
          <td 
            v-for="(date, columnIndex) in days" 
            :key="`column_${columnIndex}`"
            class="calendar__cell"
            @click="useMethod(getEventByDateTime(date, time))"
          >
            <CalendarEventComponent
              :class="{'click': type === validator[1]}"
              :event="getEventByDateTime(date, time)"
            />
          </td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex'
import CalendarEventComponent from "@/components/CalendarEventComponent";

export default {
  name: "TimeTableCalendar",
  components: {CalendarEventComponent},
  props: {
    startDate: {
      type: String,
      default: null
    },
    requests: {
      type: Array,
      default:() => [],
    },
    times: {
      type: Array,
      default: () => {
        return [
          {'from': '10:00', 'to': '13:00'},
          {'from': '13:00', 'to': '16:00'},
          {'from': '16:00', 'to': '19:00'},
          {'from': '19:00', 'to': '22:00'},
        ]
      }
    },
    type: {
      type: String,
      default: 'default'
    }

  },
  computed: {
    ...mapGetters({
      days: 'getWeekDates'
    }),
  },
  data: function () {
    return {
      dateFrom: this.startDate,
      columns: [],
      rows: [],
      validator: ['default', 'click']
    }
  },
  methods: {
    getEventByDateTime(date, time) {
      if(this.requests.length === 0) {
        return null;
      }
      const requestedItem = this.requests.find(x => {
        if(x.date_request.includes(date) && x.time_at_request.includes(time.from) && x.time_to_request.includes(time.to)){
          return x;
        }
      });

      return requestedItem ? requestedItem : null;
    },
    humanizeDate(date) {
      return this.$moment(date).format('dddd DD')
    },
    useMethod(item) {
      
      if(this.type === this.validator[0] || !item) {
        return;
      }

      this.$emit('setForCancel', item)
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {

  &__container {
    overflow-x: scroll;
    padding: 0 5px;
  }

  &__table {
    min-width: 1120px;
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      font-size: 14px;
      line-height: 20px;
      color: #3A3A3A;
      height: 80px;
      width: 50px;
    }

    td {
      border: 1px solid #B6B6B6;
      padding: 8px;
      width: 160px;
      height: 80px;


    }
  }


  &__cell {
    height: 100%;
    font-weight: 400;

    .click {
      cursor: pointer;
    }

    &--time {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      padding-right: 18px;
      height: 100%;
      flex: 1;
    }

    &--date {
      word-spacing: 300px;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      color: #3A3A3A;
      padding-bottom: 16px;
      text-transform: capitalize;
    }

    &--hide-on-mobile {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

}
</style>
