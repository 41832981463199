<template>
  <div class="time-table" >
    <div class="time-table--title" data-app>
      <span class="time-table-title">Расписание по адресу</span>
      <div class="time-table__select">
      <v-select
          v-if="events"
          :items="events"
          :value="(selectedEvent) ? selectedEvent.id : null"
          item-text="address"
          :menu-props="{overflowY: false}"
          item-value="id"
          dense
          :full-width="false"
          class="basic"
          hide-details
          @change="select"
      >
        <template v-slot:selection="">
          <span class="time-table__option">{{ selectedEvent.address }}</span>
        </template>
      </v-select>
      </div>
    </div>
    <div class="time-table--calendar" v-if="selectedEvent">
      <time-table-calendar :requests="selectedEvent.requests"></time-table-calendar>
    </div>
  </div>
</template>

<script>

import TimeTableCalendar from "@/components/TimeTableCalendar";
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'TimeTable',
  components: {TimeTableCalendar},
  computed: {
    ...mapGetters({
      events: 'events/getEvents',
      selectedEvent: 'events/getSelectedEvent',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedEvent: 'events/set_selected_event'
    }),
    select(item){
      this.setSelectedEvent({id: item, date: this.$moment().format('YYYY-MM-DD')})
    }
  }
}
</script>

<style lang="scss">
.time-table {
  display: flex;
  flex-direction: column;
  padding: 60px 80px 60px 80px;

  background: #fff;
  box-sizing: border-box;

  @media screen and (max-width: 1439px) {
    padding: 20px;

  }

  @media screen and (max-width: 768px) {

  }

  &--calendar {
    height: max-content;

  }

  &__option {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #3763FF;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__select {
    width: 100%;
    flex: 1;
  }
  &--title {
    display: inline-flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;

    .v-input__slot {
      height: 40px;
    }

    .v-select__slot {
      height: 100%;
    }

    .v-select__selection {
      height: 100%;

      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 1439px) {
      font-size: 26px;
      flex-flow: column;
      align-items: baseline;
    }

    @media screen and (max-width: 768px) {

    }

    .time-table-title {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.4px;
      color: #000000;
      text-align: start;
      white-space: nowrap;
    }

    .v-select__selection--comma {
      color: #3763FF !important;
      font-size: 32px;
      font-weight: 400;
      // overflow: hidden !important;


      @media screen and (max-width: 1439px) {
        font-size: 26px;

      }

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }


}



</style>
