<template>
  <div class='footer' v-if="!routerName">
    <div class="footer__title">
        Контактная информация
    </div>

    <div class="footer__description">
        При поддержке мэрии Казани и в согласии с уличными артистами.
    </div>
    <div class="footer__contacts">
        Контактная информация:
        <span>
          <a href="mailto:Upculture@mail.ru">park@kzn.ru</a>,&nbsp;
        </span>
        <span>
          тел.: <a href="tel:+7 (843) 239-44-33">+7 (843)239-44-33</a>
        </span>
    </div>
    <div class="footer__contacts">
      <a :href="docLink" target="_blank">Постановление</a>
    </div>
    <div class="footer__contacts">
      <a :href="pcpLink" target="_blank">Политика конфиденциальности</a>
    </div>
    <div class="footer__contacts">
     <a :href="psLink" target="_blank">Пользовательское соглашение</a>
    </div>
  </div>
</template>

<script>
export default {
    computed: {
        routerName() {
            return this.$router.history.current.name &&  ['auth', 'registration'].includes(this.$router.history.current.name)
        },
        docLink() {
          return process.env.VUE_APP_API_URL + '/docs/Постановление_Исполнительного_комитета_муниципального_образования.pdf'
        },
        pcpLink() {
          return process.env.VUE_APP_API_URL + '/docs/Политика_конфиденциальности_персональных_данных_посетителей_сайта_в_информационно-телекоммуникационной_сети_Интернет.pdf'
        },
        psLink() {
          return process.env.VUE_APP_API_URL + '/docs/Пользовательское_соглашение_с_конечным_пользователем_программно_о_обеспечения.pdf'
        }
    },
}
</script>

<style lang="scss" scoped>
    .footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 60px 0px 60px 80px;
        justify-content: flex-start;
        text-align: start;
        margin-top: auto;

        background: #151515;

        @media screen and (max-width: 1439px) {
            padding: 20px;
        }

        @media screen and (max-width: 768px) {
            
            
        }
   

        &__title {
            display: flex;
            justify-content: flex-start;

            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 40px;
            /* identical to box height, or 125% */

            letter-spacing: -0.4px;

            color: #FFFFFF;


            @media screen and (max-width: 1439px) {
                font-size: 26px;
                
            }

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        &__description {
            margin-top: 24px;

            display: flex;
            justify-content: flex-start;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */


            color: #FFFFFF;
        }

        &__contacts {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;
            
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */


            color: #FFFFFF;

            @media screen and (max-width: 768px) {
              flex-direction: column;
            }

            a {
                margin: 0 10px;
                color: #FFFFFF;
            }

            span:first-child {
              a {
                margin: 0 0 0 10px;
                @media screen and (max-width: 768px) {
                  margin: 0;
                }
              }
            }
        }

       
    }
</style>
