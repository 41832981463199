<template>
  <div class="registration-page">
    <Registration/>
  </div>
</template>

<script>
import Registration from '../components/Registration.vue'
export default {
  name: 'RegistrationView',
  components: {
    Registration
  }
}
</script>

<style lang="scss" scoped>
    .registration-page {
      
      height: 100%;

      display: flex;
      justify-content: center;

      background: #F5F5F5;
      padding-top: 56px;

      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 1;
    }
</style>
