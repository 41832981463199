export default {
    namespaced: true,
    state: {
        isAuth: false,
        api_token: '',
        user: '',
    },
    mutations: {
        ['IS_AUTH']: (state, payload) => state.isAuth = payload,
        ['API_TOKEN'] : (state, payload) => state.api_token = payload,
        ['ID_USER'] : (state, payload) => state.id_user = payload,
        ['TYPES_EVENTS']: (state, payload) => state.types_events = payload,
        ['CHOUSED_TYPE_EVENT_ID']: (state, payload) => state.choused_type_event_id = payload,
        ['SET_DATA_USER']: (state, payload) => {
            state.user = payload
        },
        ['SET_PHONE']: (state, payload) => state.phone = payload,
        ['SET_NAME']: (state, payload) => state.name = payload,
        ['SET_EMAIL']: (state, payload) => state.email = payload,
    },
    actions: {
        set_api_token: ({commit}, payload) => {
            commit('API_TOKEN', payload)
            window.localStorage.setItem("api_token", payload);
            window.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${payload}`;
            commit('IS_AUTH', true)
        },
        set_id_login: ({commit}, payload) => {
            commit('ID_USER', payload)
        },
        set_is_auth: ({commit}, payload) => {
            commit('IS_AUTH', payload)
        },
        set_data_user: ({commit}, payload) => {
            commit('SET_DATA_USER', payload)
            window.localStorage.setItem("user", JSON.stringify(payload));
        },
    },
    getters: {
        getIsAuthState: (state) =>  state.isAuth,
        getApiToken: (state) => state.api_token,
        getIdUser: (state) => state.id_user,
        getDataUser: (state) => state.user,
    }
}
