import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import moment from 'moment';
import 'moment/locale/ru';
import { VueMaskDirective } from 'v-mask';
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import {extend} from 'vee-validate';
import { required, email, regex } from 'vee-validate/dist/rules';

Vue.directive('mask', VueMaskDirective);
Vue.use(VModal)
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale('ru')
Vue.config.productionTip = false

extend('email', {
  ...email,
  message: 'Неверно заполнено поле email'
});

extend('regex', {
  ...regex,
  message: 'Введите корректное значение'
})
extend('required', {
  ...required,
  message: 'Это поле обязательно для заполнения'
});

new Vue({
  vuetify,
  router,
  store,
  provide() {
    return {
      APP_ENV: process.env.VUE_APP_ENV,
    }
  },
  render: h => h(App)
}).$mount('#app')
