<template>
  <div class="auth-page">
    <Auth/>
  </div>
</template>

<script>
import Auth from '../components/Auth.vue'
export default {
  name: 'LoginPage',
  components: {
    Auth
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
   height: 100%;

      display: flex;
      justify-content: center;

      background: #F5F5F5;
      padding-top: 56px;

      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 1;
}
</style>
