<template>
  <div class="place-form" :class="{ 'place-form__mobile': isMobile, 'is-finish': !isNotFinish}">
    <div v-if="!isMobile" class="btn-close" @click="close()">
      <v-icon>mdi-window-close</v-icon>
    </div>
    <template v-if="isNotFinish">
      <template v-if="(!isMobile && isFirstStep && selectedEvent) || (isMobile &&  selectedEvent)">
        <div class="place-form__head">
          <div class="circle">
            <img :src="getIconUrl(selectedEvent.type.type_id)" alt="">
          </div>
          <div class="place-form__head--title">
            <span>{{ selectedEvent.title }}</span>
          </div>
        </div>
        <div v-if="userIsBlocked" class="error-blocked">
          Вы заблокированы до {{ getDateUserBlocked }} включительно
        </div>
        <div class="place-form__description">
          {{ selectedEvent.description }}
        </div>
        <div class="place-form__bread-crumbs">
          <div class="place-form__bread-crumb">
            {{ selectedEvent.site_area }} м2
          </div>
          <div class="place-form__bread-crumb">
            до {{ selectedEvent.number_of_people }} человек
          </div>
        </div>

        <div class="place-form__picture" v-if="selectedEvent && selectedEvent.attachment.length !== 0">
          <div class="image" :style="{backgroundImage: `url(${selectedEvent.attachment[0].url})`}"/>
        </div>
        <div class="place-form__days">
          <div class="place-form__days--title">
            Ближайшие даты
          </div>
          <div class="place-form__days--blocks">
            <div class="day-block"
                 v-for="day in days"
                 :key="`day_${day}`"
                 @click="setDate(day)"
                 :class="{
                          'active': day === formData.date
                        }"
            >
              <div class="name ">
                {{ $moment(day).format('dd') }}
              </div>
              <div class="number">
                {{ $moment(day).format('DD') }}
              </div>
            </div>
          </div>
        </div>
        <div class="place-form__times">
          <div class="place-form__times--title">
            Время
          </div>
          <div class="place-form__times--blocks">
            <div
                class="place-form__times--block"
                v-for="item in getDateTimes"
                :key="item.id"
                @click="setTime(item)"
                :class="{
                          'active' : item.id === formData.time_id,
                          'disable' : checkStatus(item.id)}"
            >
              <div class="time">
                {{ item.time_at.slice(0, 5) }}
              </div>
            </div>
          </div>

        </div>
        <div class="place-form__choose" v-if="!isMobile">
          <v-btn class="btn-choose" :disabled="!formData.time_id" color="#3763FF" @click="nextStep" elevation="0"
                 style="color: #fff;" x-large>
            Выбрать
          </v-btn>
        </div>
      </template>

      <template v-if="(!isMobile && !isFirstStep) || isMobile">

        <div class="place-form__second" v-if="!isMobile">
          <v-btn class="btn-back" @click="backToStepOne" x-small elevation="0" color="#fff" style="color: blue;">
            <v-icon>mdi-chevron-left</v-icon>
            Изменить дату или место
          </v-btn>

        </div>
        <div class="place-form__inputs">
          <ValidationObserver v-slot="{ invalid }">
            <v-form>
              <ValidationProvider name="full_name" rules="required|alpha_spaces" imediate v-slot="{errors}">
                <v-text-field
                    :disabled="loading || userIsBlocked"
                    v-model="formData.full_name"
                    outlined
                    dense
                    :error-messages="errors"
                    placeholder="ФИО"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="email" :rules="{required: true, email: true, regex:/^([^а-яА-Я])*$/}" imediate
                                  v-slot="{errors}">
                <v-text-field
                    :disabled="loading || userIsBlocked"
                    v-model="formData.email"
                    :error-messages="errors"
                    outlined
                    dense
                    placeholder="Электронная почта"
                    type="email"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="phone" :rules="{regex:/^\+7{1}\ \(\d{3}\)\ \d{3}-\d{2}-\d{2}$/, required: true}"
                                  imediate v-slot="{errors}">
                <v-text-field
                    :disabled="loading || userIsBlocked"
                    v-model="formData.phone"
                    outlined
                    type="phone"
                    dense
                    return-masked-value
                    v-mask="'+7 (###) ###-##-##'"
                    :error-messages="errors"
                    color="deep-purple"
                    placeholder="+7"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider name="team_name" rules="required|alpha_spaces" imediate v-slot="{errors}">
                <v-text-field
                    :disabled="loading || userIsBlocked"
                    v-model="formData.team_name"
                    outlined
                    dense
                    :error-messages="errors"

                    placeholder="Название коллектива или творческий псевдоним"
                ></v-text-field>
              </ValidationProvider>
              <vue-tags-input
                  v-model="tag"
                  :tags="formData.persons"
                  class="tags-input"
                  ref="tags-input"
                  placeholder="ФИО участников"
                  @tags-changed="newTags => formData.persons = newTags"
              />
              <ValidationProvider name="category" rules="required|alpha_spaces" imediate v-slot="{errors}">
                <v-text-field
                    :disabled="loading || userIsBlocked"
                    v-model="formData.category"
                    outlined
                    dense
                    :error-messages="errors"
                    placeholder="Жанр выступления"
                ></v-text-field>
              </ValidationProvider>

              <v-file-input
                  v-if="false"
                  :disabled="loading || userIsBlocked"
                  label="Прикрепите фото"
                  outlined
                  dense
                  id="input-file"
                  prepend-icon=""
                  @change="setPhoto"

              ></v-file-input>
              <div id="captcha-container" ref="captcha-container"></div>
              <div class="place-form__choose">
                <v-btn @click="submit()" class="btn-choose" :loading="loading"
                       :disabled="loading || invalid || formData.persons.length === 0 || !formData.time_id || !formData.smart_token"
                       color="#3763FF"
                       elevation="0"
                       style="color: #fff;"
                       x-large>
                  Забронировать
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>
        </div>
      </template>
    </template>

    <template v-if="!isNotFinish">
      <div class="place-form__finish-title">
        Готово!
      </div>
      <div class="place-form__finish-description">
        Ваша заявка принята.
      </div>
      <div class="place-form__choose">
        <v-btn class="btn-choose" color="#3763FF" @click="close(false)" elevation="0" style="color: #fff;" x-large>
          Хорошо
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {api} from "@/api/api";
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import VueTagsInput from '@johmun/vue-tags-input';
import moment from "moment";

export default {
  name: 'PlaceForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    VueTagsInput
  },
  data: function () {
    return {
      isFirstStep: true,
      isNotFinish: true,
      tag: '',
      loading: false,
      formData: {
        date: this.$moment().format('YYYY-MM-DD'),
        form: false,
        password: '',
        phone: '',
        full_name: '',
        persons: [],
        email: '',
        team_name: '',
        category: '',
        file: null,
        time_id: null,
        smart_token: null
      },
    }
  },
  computed: {
    ...mapGetters({
      getIsAuthState: 'auth/getIsAuthState',
      getDataUser: 'auth/getDataUser',
      selectedEvent: 'events/getSelectedEvent',
      days: 'getWeekDates',
      getUserEvents: 'events/getUserEvents'
    }),
    userIsBlocked() {
      return this.getDataUser.is_blocked
    },
    getDateTimes() {
      return this.selectedEvent.times;
    },
    isMobile() {
      return this.$parent.isMobile
    },
    getDateUserBlocked() {
      return new moment(this.getDataUser.date_blocked_to).format('DD.MM.YYYY')
    }
  },
  mounted() {
    if (this.isMobile) {
      this.setCapture();
    }
  },
  watch: {
    selectedEvent: function (from, to) {
      this.formData.time_id = null
    }
  },
  methods: {
    ...mapActions({
      set_selected_event: 'events/set_selected_event',
      set_user_events: 'events/set_user_events',
      showAlert: 'showAlert'
    }),
    setPhoto(e) {
      console.log(e)
    },
    getIconUrl(id) {
      try {
        return require(`@/assets/svg/category_${id}.svg`);
      } catch (e) {
        return require(`@/assets/svg/default.svg`)
      }
    },
    setDate(date) {
      this.set_selected_event({id: this.selectedEvent.id, date: this.$moment().format('YYYY-MM-DD')});
      this.formData.date = date;
      this.formData.time_id = null;
    },
    checkStatus(time_id) {
      if (this.selectedEvent && this.selectedEvent.requests) {
        const time_now = this.$moment().diff(this.$moment(this.formData.date).format('YYYY-MM-DD'), 'minutes');
        return this.selectedEvent.requests.some(el => {
          return el.art_event_time_id === time_id && this.$moment().diff(el.date_request, 'minutes') === time_now;
        });
      }
      return false

    },
    setTime(time) {

      const time_now = this.$moment().diff(this.$moment(this.formData.date).format('YYYY-MM-DD'), 'minutes');
      const isItem = this.selectedEvent.requests.find(el => {
        return el.art_event_time_id === time.id && this.$moment().diff(el.date_request, 'minutes') === time_now;
      });

      if (!isItem) {
        this.formData.time_id = time.id;
        return;
      }
      return
    },
    async submit() {

      this.loading = true;

      try {
        const res = await api.events.set_event(this.selectedEvent.id, this.formData.file ? {
          api_token: this.getApiToken,
          // file: this.formData.file,
          time_id: this.formData.time_id,
          date: this.formData.date,
          email: this.formData.email,
          fio: this.formData.full_name,
          phone: this.formData.phone.slice(1),
          members: this.formData.persons,
          team_name: this.formData.team_name,
          category: this.formData.category,
          smart_token: this.formData.smart_token
        } : {
          api_token: this.getApiToken,
          time_id: this.formData.time_id,
          email: this.formData.email,
          date: this.formData.date,
          fio: this.formData.full_name,
          phone: this.formData.phone.slice(1),
          members: this.formData.persons.map(el => el.text),
          team_name: this.formData.team_name,
          category: this.formData.category,
          smart_token: this.formData.smart_token
        })
        window.smartCaptcha.reset();
        this.formData.smart_token = null;
        this.isNotFinish = false;

      } catch (e) {
        const data = e.response.data;
        switch (data.message) {
          case "the user has already signed up for that day":
            this.showAlert({
              title: 'У вас уже есть заявка =(',
              text: 'От вас уже есть заявка на ' + data.data.date_request + '. Время: ' + data.data.time_at_request + ' - ' + data.data.time_to_request
            })
            break;

          case "the place is already taken'":
            this.showAlert({
              title: 'Это время уже занято =(',
              text: 'Другой пользователь уже оставил заявку на это время, пожалуйста выберете другое время...'
            })
            break;

          case "bad smart token":
            this.showAlert({
              title: 'Ошибка capture =(',
              text: 'Видимо вы всётаки робот...'
            })
            break;

          case "this request is blocked":
            this.showAlert({
              title: 'Данная заявка уже заблокирована администратором =(',
              text: 'Вы можете выбрать любое другое удобное для Вас время...'
            })
            break;

          case "stock temporary cell is locked":
            this.showAlert({
              title: 'Данная временная ячейка заблокирована администратором до ' + data.data.date_to + ' включительно =(',
              text: 'Вы можете выбрать любое другое удобное для Вас время...'
            })
            break;
        }
        window.smartCaptcha.reset();
        this.formData.smart_token = null;
      }

      this.loading = false

    },
    setCapture() {
      if (!window.smartCaptcha) {
        return;
      }
      this.$nextTick(() => {
        window.smartCaptcha.render(this.$refs["captcha-container"], {
          sitekey: process.env.VUE_APP_CAPTCHA_TOKEN,
          callback: () => {
            this.formData.smart_token = document.querySelector('input[name="smart-token"]').value
          },
        })
      });
    },
    nextStep() {
      if (this.getIsAuthState) {
        this.isFirstStep = false;
        this.setCapture()
        return
      }
      this.$router.push({name: 'login'});
    },
    backToStepOne() {
      this.isFirstStep = true;
    },
    close(hide = true) {

      this.isFirstStep = true;
      this.isNotFinish = true;
      this.tag = '';
      this.loading = false;
      this.formData = {
        date: this.$moment().format('YYYY-MM-DD'),
        form: false,
        password: '',
        phone: '',
        full_name: '',
        persons: [],
        email: '',
        team_name: '',
        category: '',
        file: null,
        time_id: null
      }

      if (!this.isMobile && hide) {
        this.$emit('close')
      }

    }
  }

}
</script>

<style lang="scss">
.place-form {
  width: 100%;

  box-sizing: border-box;
  background: #ffff;

  padding: 24px;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  position: absolute;
  top: 24px;
  max-width: 500px;
  right: 104px;
  z-index: 100;
  max-height: 100vh;

  @media screen and (max-width: 1439px) {
    padding: 16px;
    right: 52px;
  }

  @media screen and (max-width: 768px) {
    
  }

  &__mobile {
    box-shadow: none;
    position: unset;
    max-height: unset;
    max-width: unset;
  }

  &__picture {
    width: 308px;
    height: 120px;

    .image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }


  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
  }


  &__finish-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #000000;
    text-align: left;

    @media screen and (max-width: 1439px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 16px;
    }
  }


  .tags-input {
    margin-bottom: 20px;

    max-width: 100%;
  }

  &__finish-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: start;

    color: #000000;

    margin-top: 24px;

    @media screen and (max-width: 1439px) {
      font-size: 12px;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }

  }

  &__head {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    gap: 10px;

    .circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #C4C4C4;

      img {
        width: 100%;
        height: 100%;
      }

      /*@media screen and (max-width: 1439px) {
        display: none;
      }*/

    }

    &--title {

      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      text-align: start;


      @media screen and (max-width: 1439px) {

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        max-width: 100%;

      }

      @media screen and (max-width: 768px) {
        max-width: 228px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

      }
    }
  }

  .v-text-field--outlined fieldset {
    border: 1px dashed;
  }


  &__inputs {
    margin-top: 24px;

    @media screen and (max-width: 1439px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  &__second {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    text-align: start;
    margin-top: 16px;

    @media screen and (max-width: 1439px) {
      margin-top: 12px;
      font-size: 12px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  &__bread-crumbs {
    display: flex;
    align-items: center;
    margin-top: 8px;
    flex-wrap: wrap;
  }

  &__bread-crumb {
    padding: 4px 16px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    color: #FFFFFF;
    border-radius: 4px;
    margin-left: 8px;

    @media screen and (max-width: 1439px) {
      padding: 4px 10px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }

    &:nth-child(1) {
      background: #6F6F6F;
      margin-left: 0;
    }

    &:nth-child(2) {
      background: #979797;

    }
  }

  &__picture {
    margin-top: 24px;
    width: 266px;
    height: 177px;

    background-size: cover;
    background-repeat: no-repeat;


    @media screen and (max-width: 1439px) {
      margin-top: 18px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }
  }

  &__days {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    align-items: flex-start;

    @media screen and (max-width: 1439px) {
      margin-top: 15px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 10px;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */

      @media screen and (max-width: 1439px) {
        font-size: 16px;
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }


      color: #000000;
    }

    &--blocks {
      display: flex;
      gap: 8px;

      @media screen and (max-width: 1439px) {
        margin-top: 5px;
      }

      @media screen and (max-width: 768px) {

      }


      .day-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transition: all .5s ease-in-out;

        width: 48px;
        height: 48px;
        border-radius: 4px;

        border: 1px solid #868686;

        margin-left: 8px;
        margin-top: 5px;
        cursor: pointer;

        @media screen and (max-width: 1439px) {
          width: 38px;
          height: 38px;
        }

        @media screen and (max-width: 768px) {
          width: 35px;
          height: 35px;
        }

        &:nth-child(1) {
          margin-left: 0;
        }


        .name {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* or 143% */
          color: #4F4F4F;
          text-transform: uppercase;


          @media screen and (max-width: 1439px) {
            font-size: 12px;
          }

          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }

        .number {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* or 143% */


          color: #4F4F4F;

          @media screen and (max-width: 1439px) {
            font-size: 12px;
          }

          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }

        &.active {
          background: #3763FF;
          border-radius: 4px;
          border: 0px solid #fff;
          transition: all .5s ease-in-out;

          .name, .number {
            color: #fff;
          }
        }
      }
    }
  }

  &__times {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;

    @media screen and (max-width: 1439px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }

    &--title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */


      color: #000000;


      @media screen and (max-width: 1439px) {
        font-size: 16px;
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    &--blocks {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 18px;

      @media screen and (max-width: 1439px) {
        margin-top: 10px;
      }

      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }

    &--block {
      padding: 8px 16px;
      align-items: center;
      justify-content: center;
      border: 1px solid #868686;
      box-sizing: border-box;
      border-radius: 4px;

      margin-left: 8px;
      cursor: pointer;

      @media screen and (max-width: 1439px) {
        padding: 4px 10px;
      }

      @media screen and (max-width: 768px) {
        padding: 8px 10px;
      }

      .time {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */


        color: #4F4F4F;

        @media screen and (max-width: 1439px) {
          font-size: 14px;
        }

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }

      }

      &:nth-child(1) {
        margin-left: 0;
      }

      &.active {
        background: #3763FF;
        border-radius: 4px;

        border: 0px solid #fff;

        .time {
          color: #fff;
        }
      }

      &.disable {
        background: #DEDEDE;
        border: 1px solid #A5A5A5;

        .time {
          color: #A5A5A5;
        }
      }
    }
  }


  &__choose {
    margin-top: 24px;
    width: 100%;


    @media screen and (max-width: 1439px) {
      margin-top: 10px;
    }

    @media screen and (max-width: 768px) {

    }

    .btn-choose {
      width: 100%;
    }
  }

  .error-blocked {
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background: #f5c2c7;
    color: #842029;
    border: 1px solid #842029;
    border-radius: 4px;
  }
}
</style>
