<template>
     <div class="categories" v-if="!!category_list">
        <div class="categories--block">
            <div class="categories--title">
                Как вы хотите выступить?
                <div class="categories--arrow-down" :class="[isAllHeigh && 'all-height']">
                    <v-icon color="black" @click="openCategories()">mdi-chevron-down</v-icon>
                </div>
            </div>
            <div class="categories--items" v-if="!isAllHeigh">
                <div class="categories--item" v-for="item in category_list" :key="item.id">
                    <div class="categories--circle">
                        <Icon :type_id="item.id"/>
                    </div>
                    <div class="categories--container">
                        <div class="categories--item-title" v-html="categoriesTitles[item.id - 1]" />
                        <div class="categories--item-description">
                            <!-- {{ item.description}} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Icon from "@/components/Icon";
export default {
    name: 'categories',
    components: {
        Icon
    },
    data: function() {
        return {
          isAllHeigh: false,
          categoriesTitles: [
            'Театральное выступление',
            'Музыкальное выступление<br>(до 90дб)',
            'Музыкальное выступление<br>без оборудования',
            'Площадка для художников',
            'Музыкально-акустическое<br>выступление (до 60дб)'
          ]
        }
    },
    computed: {
     ...mapGetters({
       category_list: 'events/getTypesEvents',
     })
    },
    methods: {
        openCategories() {
            this.isAllHeigh = !this.isAllHeigh
        }
    }
   
}
</script>

<style lang="scss" >
.categories {
  display: flex;
  padding: 0 80px;
  margin-top: 40px;
  margin-bottom: 24px;

  position: relative;
  z-index: 2;

  @media screen and (max-width: 1439px) {
    padding: 0 36px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  &--arrow-down {
    display: none;

    @media screen and (max-width: 768px) {
       display: block;

       &.all-height {
           transform: rotate(180deg);
       }
    }
  }

  &--block {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 32px;
    border-radius: 4px;
    width: 100%;

    @media screen and (max-width: 1439px) {
      padding: 32px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 768px) {
      padding: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &--title {
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    text-align: start;
    font-style: normal;
    font-weight: 600;

    @media screen and (max-width: 1440px) {
      font-size: 18px;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
    }
  }

  &--items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 24px;
    grid-gap: 24px 44px;

    @media screen and (max-width: 1439px) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
      grid-gap: 20px 40px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      margin-top: 10px;
      grid-gap: 10px 0px;
    }
  }

  &--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 16px;

    @media screen and (max-width: 1439px) {
      margin-left: 14px;
    }

    @media screen and (max-width: 768px) {
      margin-left: 12px;
    }
  }

  &--item {
    display: flex;
    align-items: flex-start;

    &-title {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      text-align: start;

      @media screen and (max-width: 1439px) {
        font-size: 16px;
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }


    &-description {
      margin-top: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      max-width: 285.33px;
      text-align: start;
    }
  }

  &--circle {
    width: 40px;
    height: 40px;
    left: 0px;
    top: 0px;
  }
}
</style>
